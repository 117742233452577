@import "../../../less/colors.less";
@import "../../../less/sizes.less";

.MidwayReport {
    display: flex;
    flex-direction: column;

    padding: 1.5 * @grid-cell 0;

    .Top {
        display: flex;
        flex-direction: row;

        > div {
            display: flex;
            flex-direction: column;

            width: 22%;

            .Title {
                font-size: @h5;
                margin-bottom: @grid-cell / 2;
            }

            .Value {
                font-size: @p;
                font-weight: bold;
            }
        }
    }

    .Hr {
        height: 1px;
        background-color: fade(white, 33%);
        margin: 2 * @grid-cell 0;
    }

    .Bottom {

    }
}

.VelocityView {
    position: relative;
    display: flex;
    flex-direction: column;

    .Ticker {
        @size: 12px; // width
        position: absolute;
        margin-left: -@size / 2;
        top: -(@size + 4px);
        width: 0;
        height: 0;
        border-left: @size / 2 solid transparent;
        border-right: @size / 2 solid transparent;
        border-top: @size solid white;

        > div {
            position: absolute;
            bottom: @size + 2px;
            font-size: 1.1em;
            font-weight: bold;
            left: -50px;
            right: -50px;
            text-align: center;
        }
    }

    .VelocityBars {
        position: relative;
        width: 100%;
        height: 80px;
        margin-bottom: 8px;
    }

    .VelocityLine {
        position: relative;
        width: 100%;
        height: 8px;
        margin-bottom: 12px;
    }

    .VelocityMarkers {
        margin-top: -10px;
        display: flex;
        justify-content: space-between;
        font-size: 1.1em;
        font-weight: bold;
    }

    .VelocityKinds {
        margin-top: 10px;
        display: flex;
        flex-direction: row;

        > div {
            flex: 1;
            font-size: @p;
            font-weight: bold;
            text-align: center;
        }
    }
}
