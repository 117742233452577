@import "../../../less/colors.less";
@import "../../../less/sizes.less";

.LiveView {
    position: relative;

    height: 100%;
    width: 100%;

    .Top {
        display: flex;
        flex-direction: column;

        .TypeAndLoad {
            display: flex;

            margin-top: 1 * @grid-cell;
            margin-bottom: 1 * @grid-cell;

            > div {
                display: flex;
                flex-direction: column;

                &:first-child {
                    width: 70%;
                }

                .Kind {
                    font-size: @h5;
                    margin-bottom: @grid-cell / 2;
                }

                .Value {
                    font-size: @p;
                    font-weight: bold;
                }
            }
        }

        .BigNumbers {
            display: flex;
            flex-direction: row;
            align-items: center;

            .Number {
                width: 70%;
                font-size: @h3 * 0.9;
                font-weight: 400;
                > span {
                    font-size: @h3 * 0.55;
                }
            }

            .Indicator {
                font-size: @p;
                font-weight: bold;
            }
        }

        .Motivation {
            margin-top: 5 * @grid-cell;

            font-size: @h1;
            font-weight: lighter;
        }
    }

    .Bottom {
        position: absolute;

        bottom: 0;
        left: 0;
        right: 0;

        background-color: fade(black, 25%);
        margin: -2 * @grid-cell -4 * @grid-cell;
        margin-bottom: 0;
        padding: 2 * @grid-cell 4 * @grid-cell;
    }
}
