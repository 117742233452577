@import "../../../less/colors.less";
@import "../../../less/sizes.less";

.QR {
    display: flex;
    flex-direction: row;
    width: fit-content;

    opacity: 0;
    margin-top: -150px;

    transition:
        opacity 0.25s ease-in-out 0.25s,
        margin 0.25s ease-in-out 0.25s;

    &:global(.active) {
        opacity: 1;
        margin-top: 0;
    }

    .QRWrapper {
        z-index: 1;
        background-color: white;
        padding: @grid-cell / 3;
        border-radius: @grid-cell;
        border: @grid-cell / 3 solid @ux-green-medium;

        &:global(.auth) {
            border-color: @brand-gold;
        }

        .QRCode {

        }

        .QRMock {
            height: 96px;
            width: 96px;
        }
    }

    .Info {
        display: flex;
        align-items: center;
        color: @brand-white;
        background-image: linear-gradient(to right, fade(white, 16.8%), transparent);
        max-width: 300px;
        margin-left: -@grid-cell;
        padding-left: 1.618 * @grid-cell;
        font-size: @h2 * 0.8;
    }
}
