@import "../../../less/colors.less";
@import "../../../less/sizes.less";

.Background {
    position: absolute;
    overflow: hidden;
    z-index: 0;

    height: 100%;
    width: 100%;

    background: @brand-black;

    .TrafficLight {
        position: absolute;

        height: 100%;
        width: 100%;

        transition: opacity 0.1s ease-in-out;

        @blur-size: 14;

        .Box_TopLeft {
            position: absolute;
            z-index: 1;

            top: 0;
            left: 0;

            height: 1920px * 0.35 - @grid-cell * @blur-size;
            width: 1080px * 0.6 - @grid-cell * @blur-size;

            transition: background-color 0.1s ease,
                box-shadow 0.1s ease;
        }

        .Box_TopRight {
            position: absolute;

            box-shadow: 0 0 @blur-size * @grid-cell * (5/7) @blur-size * @grid-cell white;

            top: 0;
            right: 0;

            height: 1920px * 0.35 - @grid-cell * 1.1 * @blur-size;
            width: 1080px * 0.4 - @grid-cell * @blur-size;

            opacity: 0;

            background: white;
        }

        .Box_BottomLeft {
            display: none;

            // position: absolute;

            // box-shadow: 0 0 @blur-size * @grid-cell @blur-size * @grid-cell @ux-green-dark;

            // bottom: 0;
            // left: 0;

            // height: 1920px * 0.6 - @grid-cell * @blur-size;
            // width: 1080px * 0.6 - @grid-cell * @blur-size;

            // background: @ux-green-dark;
        }

        .Box_BottomRight {
            display: none;

            // position: absolute;

            // box-shadow: 0 0 @blur-size * @grid-cell @blur-size * @grid-cell @ux-red-dark;

            // bottom: 0;
            // right: 0;

            // height: 1920px * 0.6 - @grid-cell * @blur-size;
            // width: 1080px * 0.4 - @grid-cell * @blur-size;

            // background: @ux-red-dark;
        }
    }

    .Border {
        z-index: 1000;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border: 15px solid white;
        opacity: 0;

        &.show {
            animation: border-opacity 0.4s normal forwards;
        }

        @keyframes border-opacity {
            0% {
                opacity: 1;
            }
            50% {
                opacity: 1;
            }
            100% {
                opacity: 0;
            }
        }
    }
}
