@import "../../../less/colors.less";
@import "../../../less/sizes.less";


.Results {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.Counter {
    position: absolute;

    bottom: 0;
    left: 0;
    right: 0;
    height: 10px;
    background-color: @ux-green-dark;
    border-top: 1px solid @ux-green-medium;

    > .CounterText {
        position: absolute;
        display: flex;
        justify-content: space-between;
        bottom: 15px;
        right: 10px;
        left: 10px;
        font-size: 24px;

        > span:nth-child(1) {
            font-weight: bold;
        }

        > span:nth-child(2) {
        }
    }

    > .CounterBar {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 10px;
        background-color: @ux-green-medium;
    }
}

.Tap {
    display: flex;
    flex-direction: row;

    align-items: center;

    > div:first-child {

        > img {
            width: 50px;
        }
    }

    > div:nth-child(2) {
        font-size: @h5;
        margin-left: @grid-cell / 2;
    }
}

.Top {
    display: flex;
    flex-direction: column;
    padding: 2 * @grid-cell 0;

    .TopTop {
        display: flex;

        .Left {
            font-size: @h2;
            font-weight: bold;
            flex: 2;
            line-height: 1.5;
            padding-right: 2 * @grid-cell;
        }
        .Right {
            padding-top: 7px;
            font-size: @h5;
            line-height: 1.5;
            flex: 1;
            padding-right: 1 * @grid-cell;
        }
    }

    .TopBottom {
        display: flex;
        justify-content: space-between;
        margin-top: @grid-cell * 2;

        > div:first-child {
            margin-right: @grid-cell * 10;
        }

        .Thing {
            display: flex;
            flex-direction: column;

            > div:first-child {
                font-size: @h5;
                margin-bottom: @grid-cell / 2;
            }
            > div:nth-child(2) {
                font-size: @h2;
                font-weight: bold;
            }
        }
    }
}

.BottomWrapper {
    position: absolute;

    bottom: 0;
    left: 0;
    right: 0;
}

.Bottom {

    display: flex;
    flex-direction: column;

    line-height: 1.5;

    flex: 1;

    background-color: fade(black, 50%);
    margin: 0 -4 * @grid-cell;
    margin-bottom: -2 * @grid-cell;
    padding: 0 4 * @grid-cell;
    padding-left: 4 * @grid-cell - 36.5px;
}

.WithDescription {
    display: flex;
    flex-direction: row;
    margin-top: 3 * @grid-cell;

    > .DescriptionView {
        display: flex;
        flex-direction: row;
        width: 20%;

        > img {
            margin-right: @grid-cell / 2;
            margin-top: -2px;
            height: fit-content;
        }

        > div {
            display: flex;
            flex-direction: column;
            font-size: @h5;

            > div:nth-child(1) {
                font-weight: bold;
                margin-bottom: @grid-cell / 2;
            }

            > div:nth-child(2) {
                opacity: 0.618;
            }

            > .Footnote {
                opacity: 0.618;
                display: flex;
                flex-direction: row;
                margin-top: 15px;

                > sup {
                    width: 8px;
                }

                > div {
                    margin-left: 5px;
                }
            }
        }
    }

    > div:nth-child(2) {
        position: relative;
        padding-left: 2 * @grid-cell;
        width: 80%;
        padding-top: 20px;
    }
}

.VelocityGraph {
    width: 100%;
    position: relative;
    height: 200px;

    .VGraphView {
        position: relative;
        height: 100%;

        > span {
            position: absolute;
            opacity: 0.618;
        }

        > .VVerticalLine {
            position: absolute;
            width: 1px;
            height: 100%;
            background-color: mix(@ux-bw-62, transparent, 50%);
        }

        > .VHorizontalLine {
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: @ux-bw-62;
        }

        > .VDataPoint {
            @dp-size: 15px;
            z-index: 1;
            position: absolute;
            width: @dp-size;
            margin-left: -@dp-size / 2 + 0.5px;
            height: @dp-size;
            margin-bottom: -@dp-size / 2 + 0.5px;
            border-radius: @dp-size / 2;
            background-color: white;

            > span {
                position: absolute;
                bottom: -25px;
                margin-left: 12px;
                opacity: 0.8;
            }
        }
    }
}

.DistanceGraph {
    width: 100%;
    position: relative;
    height: 150px;

    .DGraphView {
        position: relative;
        height: 100%;

        > span {
            position: absolute;
            opacity: 0.618;
        }

        > .DVerticalLine {
            position: absolute;
            width: 1px;
            height: 100%;
            background-color: mix(@ux-bw-62, transparent, 50%);
        }

        > .DHorizontalLine {
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: @ux-bw-62;
        }

        > .DDataPoint {
            @dp-size: 15px;
            z-index: 1;
            position: absolute;
            width: @dp-size;
            margin-left: -@dp-size / 2 + 0.5px;
            height: @dp-size;
            border-radius: @dp-size / 2;
            background: linear-gradient(180deg, transparent, white);

            > span {
                position: absolute;
                bottom: -25px;
                margin-left: 12px;
                opacity: 0.8;
            }
        }
    }
}

.Details {
    position: relative;
    display: flex;
    flex-direction: column;

    > .VelocityLegend {
        position: absolute;
        top: -40px;
        left: 4%;
        width: 22%;
        opacity: 0.618;
        text-align: center;

        > div {
            position: absolute;
            bottom: -10px;
            width: 100%;
            height: 6px;
            border: 1px solid white;
            border-bottom: none;
        }
    }

    > .DetailsDataView {

        > div:nth-child(1) {
            display: flex;
            flex-direction: row;
            margin-bottom: 15px;
            opacity: 0.618;

            > div {
                width: 95% / 6;
            }

            > div:first-child {
                width: 5%;
            }
        }

        > div:nth-child(2) {
            display: flex;
            flex-direction: column;

            > .DetailLine {
                display: flex;
                flex-direction: row;
                margin-bottom: 5px;

                > div {
                    width: 95% / 6;

                    &:global(.red) {
                        color: @ux-red-light;
                    }
                    &:global(.green) {
                        color: @ux-green-light;
                    }
                    &:global(.gray) {
                        opacity: 0.618;
                    }
                }

                > div:first-child {
                    width: 5%;
                    opacity: 0.618;
                }

                > div:not(:first-child) {
                    padding-left: 7.5px;
                }

                > div:last-child {
                    > span {
                        &:not(:last-child) {
                            margin-right: 5px;
                        }
                    }
                }
            }
        }
    }

    > .DetailsAvgView {
        display: flex;
        flex-direction: column;
        margin-top: 20px;

        .AvgLine {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 7.5px 0;
            border-top: 1px solid @ux-bw-62;

            > div:nth-child(1) {
                opacity: 0.618;
            }

            > div:nth-child(2) {
                padding-left: 7.5px;
                width: 92.5% / 4;
            }
        }
    }
}
