@import "../../../less/colors.less";
@import "../../../less/sizes.less";

.WeighIn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 100%;
    width: 100%;

    > div {
        width: 800px;
        display: flex;
        flex-direction: column;
    }
}

.Settings {
    margin-top: 50px;
    padding-left: 8px;

    > .SettingsHeader {
        font-size: 26px;
        font-weight: bold;

        > span {
            font-weight: normal;
        }
    }

    > .Setting {
        display: flex;
        align-items: center;
        font-size: 26px;
        margin-left: -16px;
        margin-top: 20px;

        > .SettingName,
        > .SettingValue {
            padding: 4px 8px;
            border-radius: 10px;
            margin-left: 8px;
            margin-right: 8px;
            background-color: white;
            color: @brand-black;
            font-size: 26px;
            font-weight: bold;
        }

        > .SettingName {

        }

        > .SettingValue {

        }
    }
}

.TitleAndQR {
    display: flex;
    flex-direction: column;

    padding-left: 60px + 96px;
    margin-bottom: @grid-cell * 7;

    .AppName {
        font-size: 96px;
        font-weight: normal;

        margin-bottom: @grid-cell * 2;
    }
}

.Instruction {
    display: flex;
    flex-direction: row;
    margin-bottom: @grid-cell * 7;

    opacity: 0.2;
    transition: .2s opacity ease-in-out;

    &:global(.active) {
        opacity: 1;
    }

    .Left {
        display: flex;
        flex-direction: column;
        width: 600px;
        padding-left: 60px;

        .Step {
            font-size: @h1 *0.8;
            font-weight: 300;
            color: @ux-bw-62;
        }

        .Header {
            font-size: @h1;
            font-weight: normal;
            margin-bottom: @grid-cell * 2;
        }

        .Description {
            font-size: @h2;
            padding-right: 10px;;
        }
    }

    .Right {
        display: flex;
        flex-direction: column;
        padding-top: 30px;

        .Icon {
            margin-bottom: @grid-cell;
        }

        // .IconText {
        //     width: 150px;
        //     font-size: @p * 0.75;
        //     margin-left: @grid-cell;
        // }
    }
}
