@import "../../../less/sizes.less";

.TopBar {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 2 * @grid-cell;
    max-height: 2 * @grid-cell;
    margin-bottom: 2 * @grid-cell;

    > .LogoWrapper {
        display: flex;
        flex-direction: row;
        align-items: flex-end;

        > .LogoSymbol {
            height: 29px;

            > img {
                max-height: 100%;
            }
        }

        > .RightWrapper {
            display: flex;
            flex-direction: row;
            align-items: center;

            > .LogoType {
                > p {
                    margin: 0px;
                    margin-left: 15px;
                    transform: translateY(6px);
                }
            }
        }
    }
}
