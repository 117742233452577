@import "../../../less/sizes.less";
@import "../../../less/colors.less";

.User {
    position: relative;
    display: flex;
    align-items: center;
    min-width: 0;

    background-color: @ux-green-light;
    color: @brand-black;
    opacity: 0;

    height: 2 * @grid-cell;
    border-radius: @grid-cell;
    padding: 0 @grid-cell;
    padding-right: 0;

    @clock-width: 50px;
    margin-left: calc(2 * @grid-cell + @clock-width);

    font-size: @p;
    white-space: nowrap;

    > .Text {
        min-width: 0;
        overflow-x: hidden;
        text-overflow: ellipsis;

        > span:not(.Name) {
            color: fade(@brand-black, (100% * 0.618));
        }
    }

    > .Icon {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        @border-size: 1px;
        border: @border-size solid @brand-black;
        background-color: mix(white, @ux-green-light, 50%);
        box-shadow: 0 0 10px fade(@brand-black, 25%),
                    0 0 0 5px fade(@brand-black, 5%);

        margin-left: @grid-cell;
        margin-right: -@border-size;
        margin-top: -@border-size;
        margin-bottom: -@border-size;
        width: 2 * @grid-cell + 2 * @border-size;
        height: 2 * @grid-cell + 2 * @border-size;
        border-radius: @grid-cell + @border-size;
    }

    > .Clock {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 100%;
        display: flex;
        align-items: center;
        padding-right: @grid-cell;
        // font-family: monospace;
        color: mix(white, @ux-green-light, 50%);
        opacity: 0;
        transition: opacity 0.1s ease-in-out;
    }

    &.Counting > .Clock {
        opacity: 1;
    }

    > .OverlayWrapper {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: @grid-cell;
        overflow: hidden;

        > .Overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 100%;
            bottom: 0;
            background-color: fade(@brand-black, 20%);
            opacity: 0;
            transition: opacity 0.1s ease-in-out;
        }
    }

    &.Counting > .OverlayWrapper > .Overlay {
        opacity: 1;
        animation: slide-over 120s normal forwards linear;

        @keyframes slide-over {
            from {
                right: 100%;
            }
            to {
                right: 0;
            }
        }
    }

    &.SignedIn {
        animation: sign-in 0.2s normal forwards ease-in-out;
    }

    @keyframes sign-in {
        from {
            transform: scale(0.95);
            opacity: 0;
        }
        to {
            transform: scale(1);
            opacity: 1;
        }
    }

    &.SignedOut {
        animation: sign-out 0.2s normal forwards ease-in-out;
    }

    @keyframes sign-out {
        from {
            transform: scale(1);
            opacity: 1;
        }
        to {
            transform: scale(0.95);
            opacity: 0;
        }
    }
}
